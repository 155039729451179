import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {blueGrey} from '@mui/material/colors';
import {Application, Dispositions, MinMaxBatchInfo} from '@ozark/common';
import {TransitionGroup} from 'react-transition-group';
import {useStore} from '../../../../store/helpers';
import {AddApplicationAssociationDialog} from '../../../ApplicationAssociations/AddApplicationAssociationDialog';
import {RiskOverviewCard} from '../../../RiskOverview/Card';
import {useApplicationsAssociationsActions, useAssociatedApplicationsBatchReport} from './hooks';

type Props = {
  application: Application;
  readOnly?: boolean;
};

export const RiskApplicationAssociations = ({application, readOnly}: Props): JSX.Element => {
  const {profiles} = useStore();
  const {
    addAssociationLoading,
    isAddNewApplicationDialogOpen,
    openAddAssociationDialog,
    closeAddAssociationDialog,
    onSaveAddNewAssociationDialog,
    onRemoveAssociation,
  } = useApplicationsAssociationsActions({application});

  const {
    isAssociatedApplicationsBatchReportLoading,
    applicationBatchReport,
    associatedApplicationsBatchReport,
  } = useAssociatedApplicationsBatchReport({application});

  const {accountStatus, riskStatus, riskLabels, riskAssigneeUid} = application;
  const batchReport = {
    ...applicationBatchReport,
    accountStatus,
    riskStatus,
    riskLabels,
  } as MinMaxBatchInfo;

  return (
    <Box>
      <TransitionGroup>
        {applicationBatchReport && (
          <Collapse key={applicationBatchReport.id} sx={{mb: 2}}>
            <RiskOverviewCard
              data={batchReport}
              view="card"
              assigneeProfile={riskAssigneeUid ? profiles.dictionary[riskAssigneeUid] : null}
              highlighted
              hideViewDetailsButton
            />
          </Collapse>
        )}
      </TransitionGroup>

      <Paper sx={{p: 2}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 600,
              color: blueGrey[700],
              mb: 0,
            }}
            variant="body1"
            gutterBottom
          >
            <SettingsEthernetIcon sx={{ml: 1, mr: 1.5}} />
            Associated MIDS
            <Badge
              color="primary"
              max={999}
              badgeContent={associatedApplicationsBatchReport?.length}
              sx={{ml: 2, mr: 2.5}}
            />
            {isAssociatedApplicationsBatchReportLoading && <CircularProgress size={16} />}
          </Typography>

          {!readOnly && (
            <Button
              variant="outlined"
              onClick={openAddAssociationDialog}
              disabled={!application || !!application?.deleted}
            >
              Add association
            </Button>
          )}
        </Box>
      </Paper>

      <Stack sx={{pt: 2, mb: -2}}>
        <TransitionGroup>
          {associatedApplicationsBatchReport.map(applicationReport => (
            <Collapse key={applicationReport.id} sx={{mb: 2}}>
              <RiskOverviewCard
                data={applicationReport}
                view="card"
                onRemoveAssociation={onRemoveAssociation}
                assigneeProfile={
                  applicationReport.riskAssigneeUid
                    ? profiles.dictionary[applicationReport.riskAssigneeUid]
                    : null
                }
                shouldOpenDetailsInNewTab
              />
            </Collapse>
          ))}
        </TransitionGroup>
      </Stack>

      <AddApplicationAssociationDialog
        open={isAddNewApplicationDialogOpen}
        onClose={closeAddAssociationDialog}
        onSave={onSaveAddNewAssociationDialog}
        loading={addAssociationLoading}
        dispositions={[Dispositions.boarded]}
        getOptionLabel={app => `${app.legalBusinessName ?? ''} / ${app.name}`}
      />
    </Box>
  );
};
