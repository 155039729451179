import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ScorecardExecutionStatus} from '@ozark/common';
import {ScorecardServiceLogo} from '@ozark/common/components';
import clsx from 'clsx';
import {AccordionProps, StatusColorMap} from '..';

const PERSONA_BASE_URL = 'https://app.withpersona.com';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    column: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '20%',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '25%',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    processingTypeTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
    primaryColor: {
      color: theme.palette.primary.main,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    panelName: {
      width: 200,
    },
    actionsRoot: {
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '20%',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        flex: 1,
      },
    },
  })
);

type Props = AccordionProps;

export const PersonaIdAccordion = ({
  scorecardReport,
  application,
  whichExpanded,
  onChange,
}: Props) => {
  const classes = useStyles();
  const personaIdResult = application.personaId;

  return (
    <Accordion
      TransitionProps={{unmountOnExit: true}}
      expanded={whichExpanded === scorecardReport.id}
      onChange={onChange(scorecardReport.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${scorecardReport.id}-content`}
        id={`panel-${scorecardReport.id}-header`}
      >
        <div className={classes.logo}>
          <ScorecardServiceLogo scorecardService={scorecardReport.serviceName} />
          <div className={classes.panelName}>
            <Typography variant="body1">{scorecardReport.title}</Typography>
          </div>
        </div>
        <div className={classes.column}>
          <Typography variant="body1">
            Status:&nbsp;&nbsp;
            <span
              style={{
                color:
                  StatusColorMap[
                    personaIdResult?.isVerified
                      ? ScorecardExecutionStatus.complete
                      : personaIdResult?.data
                      ? ScorecardExecutionStatus.failed
                      : ScorecardExecutionStatus.neverRan
                  ],
              }}
            >
              {personaIdResult
                ? personaIdResult?.isVerified
                  ? ScorecardExecutionStatus.complete
                  : personaIdResult?.data
                  ? ScorecardExecutionStatus.failed
                  : ScorecardExecutionStatus.initialized
                : ScorecardExecutionStatus.neverRan}
            </span>
          </Typography>
        </div>
        <div className={clsx(classes.column, classes.grow)}>
          <Typography variant="body1">
            {personaIdResult?.isVerified ? (
              <>
                Result:&nbsp;&nbsp;
                <span
                  style={{
                    color: StatusColorMap[ScorecardExecutionStatus.complete],
                    textTransform: 'capitalize',
                  }}
                >
                  Verified
                </span>
              </>
            ) : (
              <>
                Result:&nbsp;&nbsp;
                <span
                  style={{
                    color: StatusColorMap[ScorecardExecutionStatus.neverRan],
                  }}
                >
                  Manually Entered
                </span>
              </>
            )}
          </Typography>
        </div>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <div className={classes.details}>
          {application.personaId?.data?.id && (
            <Typography variant="body1" component="h4">
              Verification ID:&nbsp;
              <Link
                target="_blank"
                rel="noreferrer"
                href={`${PERSONA_BASE_URL}/dashboard/verifications/${application.personaId.data.id}`}
              >
                {application.personaId.data.id}
              </Link>
            </Typography>
          )}
          {application.personaId?.isVerified && (
            <Typography variant="body1" component="h4">
              {`Driver License Number: ${application.personaId?.driverLicenseNumber}`}
            </Typography>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
