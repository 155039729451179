import {EmailOutlined} from '@mui/icons-material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ContactsIcon from '@mui/icons-material/Contacts';
import EventIcon from '@mui/icons-material/Event';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import {
  agentSupportUnassignedAppPrecalculations,
  conditionallyApprovedBoardedAppPrecalculations,
  Department,
  OzarkUserRoles,
  OzarkUserRolesWithoutSales,
  OzarkUserRolesWithoutUnderwriter,
  unassignedErpTicketPrecalculations,
  unassignedRiskAppPrecalculations,
  unassignedSalesLeadsPrecalculations,
  underwritingUnassignedAppPrecalculations,
  useCallable,
  usePrecalculations,
  UserRoles,
} from '@ozark/common';
import {CALENDAR, NavMenu, NavMenuLink, NavMenuParentLink} from '@ozark/common/components';
import {NOTIFICATIONS_ROOT} from '@ozark/common/components/NotificationsPage/routes';
import {
  AgentsIcon,
  ApplicationsIcon,
  AuthorizationsIcon,
  BoardedIcon,
  DashboardIcon,
  DepositsIcon,
  DisputesIcon,
  FraudAnalysisIcon,
  GroupsIcon,
  IncompleteIcon,
  MerchantsIcon,
  OnlineAppNewIcon,
  PortfolioACHIcon,
  PortfolioIcon,
  PortfolioPCIIcon,
  PortfolioReservesIcon,
  PortfolioTINIcon,
  PricingIcon,
  RelationshipManagementIcon,
  ReportsIcon,
  ResidualIcon,
  ResourcesIcon,
  StatementsIcon,
  TransactionsPendingIcon,
  UnderwritingIcon,
  UsersIcon,
} from '@ozark/common/icons';
import {useCallback, useMemo} from 'react';
import {DEFAULT_GROUP} from '../../constants/group';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

enum BadgeCounter {
  conditionallyApproved = 'conditionallyApproved',
  underwriting = 'underwriting',
  agentSupport = 'agentSupport',
  tickets = 'tickets',
  salesLeads = 'salesLeads',
  risk = 'risk',
  allApplications = 'allApplications',
}

const Navigator = ({...other}) => {
  const {getPortalToken} = useCallable();
  const {authProfile} = useStore();

  const {value: conditionallyApprovedCount} = usePrecalculations(
    conditionallyApprovedBoardedAppPrecalculations
  );
  const {value: agentSupportUnassignedAppCount} = usePrecalculations(
    agentSupportUnassignedAppPrecalculations
  );
  const {value: underwritingUnassignedAppCount} = usePrecalculations(
    underwritingUnassignedAppPrecalculations
  );
  const {value: unassignedSalesLeadsCount} = usePrecalculations(
    unassignedSalesLeadsPrecalculations
  );

  const {value: unassignedTicketCount} = usePrecalculations(unassignedErpTicketPrecalculations);

  const {value: riskAppUnassignedCount} = usePrecalculations(unassignedRiskAppPrecalculations);

  const handleOpenPortal = useCallback(async () => {
    const result = await getPortalToken({});

    if (result.status === 'ok') {
      const a = document.createElement('a');
      a.setAttribute('href', `${process.env.REACT_APP_APP_URL}/portal?token=${result.token}`);
      a.setAttribute('target', '_blank');
      a.click();
    } else {
      console.error('Failed to get portal token with result:', result);
    }
  }, [getPortalToken]);

  const badgeCounters = {
    [BadgeCounter.conditionallyApproved]: conditionallyApprovedCount,
    [BadgeCounter.agentSupport]: agentSupportUnassignedAppCount,
    [BadgeCounter.underwriting]: underwritingUnassignedAppCount,
    [BadgeCounter.tickets]: unassignedTicketCount,
    [BadgeCounter.salesLeads]: unassignedSalesLeadsCount,
    [BadgeCounter.risk]: riskAppUnassignedCount,
    [BadgeCounter.allApplications]:
      agentSupportUnassignedAppCount + underwritingUnassignedAppCount + conditionallyApprovedCount,
  };

  const isItemVisible = (link: NavMenuLink): boolean => {
    return Boolean(authProfile.data && link.isVisible(link));
  };

  const links = useMemo(
    () =>
      getLinksConfig(
        authProfile?.data?.role!,
        authProfile?.data?.department! as Department,
        handleOpenPortal
      ),
    [authProfile, handleOpenPortal]
  );

  return (
    <NavMenu
      links={links}
      isItemVisible={isItemVisible}
      logo={DEFAULT_GROUP.logoUrl}
      logoAlt={`${DEFAULT_GROUP.name} ERP`}
      badgeCounters={badgeCounters}
      drawerProps={other}
    />
  );
};

const getAnalyticsLinks = (role: UserRoles): NavMenuLink[] => [
  {
    name: 'Merchant Activity',
    icon: <StatementsIcon />,
    route: ROUTES.MERCHANTS_PORTFOLIO,
    isVisible: () =>
      [UserRoles.agentSupport, UserRoles.operations, UserRoles.admin, UserRoles.support].includes(
        role
      ),
  },
  {
    name: 'Portfolio Statistics',
    icon: <StatementsIcon />,
    route: ROUTES.PORTFOLIO_STATISTICS,
    isVisible: () => OzarkUserRolesWithoutUnderwriter.includes(role),
  },
  {
    name: 'Agent Statistics',
    route: ROUTES.AGENT_STATISTICS,
    icon: <FraudAnalysisIcon />,
    isVisible: () =>
      [UserRoles.agentSupport, UserRoles.operations, UserRoles.admin, UserRoles.support].includes(
        role
      ),
  },
  {
    name: 'Monthly Volume Details',
    icon: <StatementsIcon />,
    route: ROUTES.MONTHLY_VOLUME_DETAILS,
    isVisible: () => OzarkUserRolesWithoutUnderwriter.includes(role),
  },
  {
    name: 'Monthly Authorization Details',
    icon: <StatementsIcon />,
    route: ROUTES.MONTHLY_AUTHORIZATION_DETAILS,
    isVisible: () => OzarkUserRolesWithoutUnderwriter.includes(role),
  },
  {
    name: 'Yearly Summary',
    icon: <StatementsIcon />,
    route: ROUTES.YEARLY_SUMMARY,
    isVisible: () => OzarkUserRolesWithoutUnderwriter.includes(role),
  },
  {
    name: 'Agent/MID Association',
    icon: <StatementsIcon />,
    route: ROUTES.REPORTING_AGENT_MID_ASSOCIATIONS,
    isVisible: () => OzarkUserRoles.includes(role),
  },
  {
    name: 'MCCs by Sales',
    icon: <StatementsIcon />,
    route: ROUTES.ANALYTICS_MCC_BY_SALES,
    isVisible: () => OzarkUserRolesWithoutUnderwriter.includes(role),
  },
  {
    name: 'MIDs by Processing',
    icon: <StatementsIcon />,
    route: ROUTES.ANALYTICS_MIDS_BY_PROCESSING,
    isVisible: () => OzarkUserRolesWithoutUnderwriter.includes(role),
  },
  {
    name: 'MIDs by Chargebacks',
    icon: <StatementsIcon />,
    route: ROUTES.ANALYTICS_MIDS_BY_CHARGEBACKS,
    isVisible: () => OzarkUserRolesWithoutUnderwriter.includes(role),
  },
];

const getLinksConfig = (
  role: UserRoles,
  department: Department,
  handleOpenPortal: () => void
): NavMenuParentLink[] => [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    route: ROUTES.DASHBOARD,
    exact: true,
    isVisible: () => OzarkUserRolesWithoutSales.includes(role),
  },
  {
    name: 'Sales Leads',
    icon: <ContactsIcon />,
    route: ROUTES.SALES_LEADS,
    badgeCounter: BadgeCounter.salesLeads,
    isVisible: () => [UserRoles.admin, UserRoles.agentSupport, UserRoles.sales].includes(role),
    children: [
      {
        name: 'Dashboard',
        icon: <DashboardIcon />,
        route: ROUTES.SALES_LEADS_DASHBOARD,
        isVisible: () => [UserRoles.admin].includes(role),
      },
      {
        name: 'Metrics',
        icon: <FraudAnalysisIcon />,
        route: ROUTES.SALES_LEADS_METRICS,
        isVisible: () => [UserRoles.admin].includes(role),
      },
      {
        name: 'Campaign ratio',
        icon: <DashboardIcon />,
        route: ROUTES.SALES_LEADS_CAMPAIGN_RATIO,
        isVisible: () => [UserRoles.admin].includes(role),
      },
      {
        name: 'Source ratio',
        icon: <DashboardIcon />,
        route: ROUTES.SALES_LEADS_SOURCE_RATIO,
        isVisible: () => [UserRoles.admin].includes(role),
      },
      {
        name: 'Sales rep',
        icon: <DashboardIcon />,
        route: ROUTES.SALES_LEADS_SALES_REP_RATIO,
        isVisible: () => [UserRoles.admin].includes(role),
      },
    ],
  },
  {
    name: 'Applications',
    icon: <ApplicationsIcon />,
    route: ROUTES.APPLICATIONS,
    badgeCounter: BadgeCounter.allApplications,
    isVisible: () => OzarkUserRoles.includes(role),
    children: [
      {
        name: 'All Applications',
        icon: <ApplicationsIcon />,
        route: ROUTES.APPLICATIONS,
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
      },
      {
        name: 'Incomplete',
        icon: <IncompleteIcon />,
        route: ROUTES.INCOMPLETE,
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
      },
      {
        name: 'Agent Support',
        icon: <RelationshipManagementIcon />,
        route: ROUTES.AGENT_SUPPORT,
        badgeCounter: BadgeCounter.agentSupport,
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
      },
      {
        name: 'Underwriting',
        icon: <UnderwritingIcon />,
        route: ROUTES.UNDERWRITING,
        badgeCounter: BadgeCounter.underwriting,
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
      },
      {
        name: 'C Approved',
        icon: <BoardedIcon />,
        route: ROUTES.CONDITIONALLY_APPROVED,
        badgeCounter: BadgeCounter.conditionallyApproved,
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
      },
      {
        name: 'Boarded',
        icon: <BoardedIcon />,
        route: ROUTES.BOARDED,
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
      },
      {
        name: 'Submit New Application',
        icon: <OnlineAppNewIcon />,
        route: 'empty',
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
        onClick: handleOpenPortal,
      },
    ],
  },
  {
    name: 'Online App',
    icon: <PricingIcon />,
    route: ROUTES.PRICING,
    isVisible: () => [UserRoles.admin, UserRoles.support, UserRoles.agentSupport].includes(role),
    children: [
      {
        name: 'Pricing',
        icon: <PricingIcon />,
        route: ROUTES.PRICING,
        isVisible: () =>
          [UserRoles.admin, UserRoles.support, UserRoles.agentSupport].includes(role),
      },
      {
        name: 'Equipment',
        icon: <PricingIcon />,
        route: ROUTES.EQUIPMENT,
        isVisible: () =>
          [UserRoles.admin, UserRoles.support, UserRoles.agentSupport].includes(role),
      },
    ],
  },
  {
    name: 'Risk',
    icon: <WarningIcon />,
    route: ROUTES.RISK_OVERVIEW,
    badgeCounter: BadgeCounter.risk,
    isVisible: () => OzarkUserRoles.includes(role),
    children: [
      {
        name: 'Fraud Analysis',
        route: ROUTES.FRAUD_ANALYSIS,
        icon: <FraudAnalysisIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
    ],
  },
  {
    name: 'Merchant Portfolio',
    icon: <MerchantsIcon />,
    route: ROUTES.MERCHANTS_PORTFOLIO,
    isVisible: () =>
      [UserRoles.agentSupport, UserRoles.operations, UserRoles.admin, UserRoles.support].includes(
        role
      ),
    children: getAnalyticsLinks(role),
  },
  {
    name: 'MID Reporting',
    route: ROUTES.BATCHES,
    icon: <ReportsIcon />,
    isVisible: () =>
      [UserRoles.agentSupport, UserRoles.operations, UserRoles.admin, UserRoles.support].includes(
        role
      ),
    children: [
      {
        name: 'Authorizations',
        route: ROUTES.AUTHORIZATIONS,
        icon: <AuthorizationsIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'Batches',
        route: ROUTES.BATCHES,
        icon: <TransactionsPendingIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'Deposits',
        route: ROUTES.DEPOSITS,
        icon: <DepositsIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'Statements',
        route: ROUTES.STATEMENTS,
        icon: <PictureAsPdfIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'Disputes',
        route: ROUTES.CHARGEBACKS_SUMMARY,
        icon: <DisputesIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'ACH Rejects',
        route: ROUTES.PORTFOLIO_ACH_REJECTS,
        icon: <PortfolioACHIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'PCI',
        route: ROUTES.PORTFOLIO_PCI,
        icon: <PortfolioPCIIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'TIN',
        route: ROUTES.PORTFOLIO_TIN,
        icon: <PortfolioTINIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'Reserves',
        route: ROUTES.PORTFOLIO_RESERVES,
        icon: <PortfolioReservesIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'Scheduled Reports',
        route: ROUTES.SCHEDULED_REPORTING,
        icon: <EventIcon />,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
    ],
  },
  {
    name: 'Residuals',
    icon: <ResidualIcon />,
    route: ROUTES.RESIDUALS,
    redirectToFirstChild:
      ![UserRoles.admin, UserRoles.operations].includes(role) && department !== Department.finance,
    isVisible: () =>
      [UserRoles.admin, UserRoles.operations, UserRoles.sales, UserRoles.support].includes(role) ||
      [Department.sales, Department.risk, Department.logistics, Department.finance].includes(
        department
      ),
    children: [
      {
        name: 'Agent Residuals',
        icon: <AgentsIcon />,
        route: ROUTES.RESIDUALS_AGENTS,
        isVisible: () =>
          [UserRoles.admin, UserRoles.operations, UserRoles.sales, UserRoles.support].includes(
            role
          ) ||
          [Department.sales, Department.risk, Department.logistics, Department.finance].includes(
            department
          ),
      },
    ],
  },
  {
    name: 'Residuals',
    icon: <ResidualIcon />,
    route: ROUTES.RESIDUALS_AGENTS,
    isVisible: () => [UserRoles.agentSupport].includes(role) && department !== Department.finance,
  },
  {
    name: 'Tickets',
    icon: <AssignmentTurnedInIcon />,
    route: ROUTES.TICKETS,
    isVisible: () => OzarkUserRoles.includes(role),
    exact: false,
    badgeCounter: BadgeCounter.tickets,
    children: [
      {
        name: 'Types and Category Manager',
        icon: <SettingsIcon />,
        route: ROUTES.TICKET_TYPES_MANAGER,
        isVisible: () => role === UserRoles.admin,
      },
    ],
  },
  {
    name: 'Calendar',
    icon: <EventIcon />,
    route: CALENDAR,
    isVisible: () => OzarkUserRolesWithoutSales.includes(role),
  },
  {
    name: 'Resources',
    icon: <ResourcesIcon />,
    route: ROUTES.RESOURCES,
    isVisible: () => OzarkUserRoles.includes(role),
  },
  {
    name: 'Users',
    icon: <UsersIcon />,
    route: '',
    redirectToFirstChild: true,
    isVisible: () =>
      OzarkUserRoles.includes(role) ||
      [Department.sales, Department.risk, Department.logistics].includes(department),
    children: [
      {
        name: 'ERP Users',
        icon: <ManageAccountsIcon />,
        route: ROUTES.USERS,
        isVisible: () =>
          [UserRoles.admin, UserRoles.sales, UserRoles.support].includes(role) ||
          [Department.sales, Department.risk, Department.logistics].includes(department),
      },
      {
        name: 'Groups',
        icon: <GroupsIcon />,
        route: ROUTES.GROUPS,
        isVisible: () =>
          [UserRoles.admin, UserRoles.agentSupport, UserRoles.sales, UserRoles.support].includes(
            role
          ) || [Department.sales, Department.risk, Department.logistics].includes(department),
      },
      {
        name: 'Agents',
        icon: <AgentsIcon />,
        route: ROUTES.AGENTS,
        isVisible: () => OzarkUserRoles.includes(role),
      },
      {
        name: 'Portal Registration',
        icon: <StatementsIcon />,
        route: ROUTES.ACTIVATIONS,
        isVisible: () =>
          [
            UserRoles.agentSupport,
            UserRoles.operations,
            UserRoles.admin,
            UserRoles.support,
          ].includes(role),
      },
      {
        name: 'Registered Merchants',
        icon: <MerchantsIcon />,
        route: ROUTES.MERCHANTS,
        isVisible: () => OzarkUserRolesWithoutSales.includes(role),
      },
    ],
  },
  {
    name: 'Notifications',
    icon: <NotificationsIcon />,
    route: NOTIFICATIONS_ROOT,
    isVisible: () => OzarkUserRoles.includes(role),
  },
  {
    name: 'Announcements',
    icon: <SendIcon />,
    route: ROUTES.ANNOUNCEMENTS,
    isVisible: () =>
      [UserRoles.admin, UserRoles.sales, UserRoles.support].includes(role) ||
      [Department.sales, Department.risk, Department.logistics].includes(department),
  },
  {
    name: 'Email Log',
    icon: <EmailOutlined />,
    disabled: true,
    route: ROUTES.AGENTS,
    isVisible: () => OzarkUserRolesWithoutSales.includes(role),
  },
  {
    name: 'Applications',
    icon: <PortfolioIcon />,
    route: ROUTES.AUDIT,
    isVisible: () => [UserRoles.auditor].includes(role),
  },
];

export default Navigator;
